<template>
  <div class="content">
    <div class="header flex_direction_column" v-if="info">
      <span>{{ info.name }}</span>
      <div style="display: flex; align-items: center">
        <img src="@/assets/image/shijian.png" style="width: 16px; height: 16px; margin-right: 5px" />
        <span style="font-size: 16px; height: 30px; font-family: PingFang SC">需要后台返回时间</span>
      </div>
    </div>
    <div class="bottom flex_direction_column">
      <div class="search flex_center">
        <img src="@/assets/image/img/22.png" />
        <div class="box flex_space_between">
          <input v-model="key" placeholder="请输入参赛号/证件号" />
          <img @click="search" src="@/assets/image/img/21.png" />
        </div>
      </div>
      <div v-if="key && result">
        <div class="result flex_direction_column">
          <div class="result_title">查询结果</div>
          <div class="score_title">赛事成绩</div>
          <div class="score_info">
            <div class="info_main flex_direction_column">
              <div class="info_title">{{ result.active }}</div>
              <div class="time">{{ result.achievement }}</div>
              <div class="rank">第{{ result.rank }}名</div>
            </div>
            <div class="player flex_space_between">
              <div class="item flex_space_between">
                <div class="item_box">
                  <span>{{ result.name }} </span>
                  <p>姓名</p>
                </div>
              </div>
              <div class="item flex_space_between" v-if="result.sex">
                <div class="item_box">
                  <span>{{ result.sex }} </span>
                  <p>性别</p>
                </div>
              </div>
              <div class="item flex_space_between">
                <div class="item_box">
                  <span>{{ result.number }} </span>
                  <p>号码牌</p>
                </div>
              </div>
              <div class="item flex_space_between">
                <div class="item_box">
                  <span>{{ result.group }} </span>
                  <p>组别</p>
                </div>
              </div>
            </div>
          </div>
          <div class="score_title flex_space_between" v-if="result.is_certificate == 1">
            <span>赛事证书</span>
            <div class="down flex" @click="downClick(result.url)">
              <img src="../../../assets/image/icon/16.png" />
              <span>下载证书</span>
            </div>
          </div>
          <!-- :style="{ height: result.is_certificate == 1 ? '1106px' : '' }"  -->
          <div class="cer flex_center">
            <img v-if="result.is_certificate == 1" :src="result.url" />
            <Lack
              v-else
              imgWidth="104px"
              imgHeight="84px"
              text="暂无赛事证书"
              :imgSrc="require('@/assets/image/img/27.png')"
            ></Lack>
          </div>
        </div>
      </div>

      <Lack
        v-if="(!result && !isSearch && key) || !key"
        imgWidth="102px"
        imgHeight="68px"
        text="您还未查询，请输入关键词查询吧~"
        :imgSrc="require('@/assets/image/img/23.png')"
      ></Lack>
      <Lack
        v-if="!result && key && isSearch"
        imgWidth="92px"
        imgHeight="66px"
        text="暂无查询结果"
        :imgSrc="require('@/assets/image/img/26.png')"
      ></Lack>
    </div>
  </div>
</template>

<script>
import { downloadIamge } from '@/utils/tools'
import { achievement, searchResult } from '@/service/api/index'
import Lack from '@/components/lack/lack.vue'
export default {
  components: { Lack },
  data() {
    return {
      key: '',
      info: '',
      result: '',
      isSearch: false
    }
  },

  mounted() {
    this.achievement()
  },

  methods: {
    // 成绩查询
    async achievement() {
      const res = await achievement({ id: this.$route.query.id })
      this.info = res.msg
    },
    // 查询结果
    async searchResult() {
      const res = await searchResult({ id: this.$route.query.active_id, key_word: this.key })
      this.isSearch = true
      this.result = res.msg
      console.log(res)
    },
    downClick(src) {
      downloadIamge(src, '证书')
    },
    search() {
      this.searchResult()
    }
  },
  watch: {
    key() {
      this.isSearch = false
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  border-radius: 5px;
  background-color: #fff;
  padding: 31px 52px 26px 52px;
  span {
    &:nth-child(1) {
      line-height: 42px;
      font-size: @font_size_26;
    }
    &:nth-child(2) {
      margin-top: 6px;
      line-height: 25px;
      color: @color_twenty;
      font-size: @font_size_18;
    }
  }
}
.bottom {
  margin-top: 20px;
  border-radius: 5px;
  margin-bottom: 17px;
  background-color: #fff;
  padding: 71px 80px 64px 80px;
  .search {
    img {
      width: 75px;
      height: 70px;
    }
    .box {
      width: 785px;
      margin-left: 92px;
      padding: 15px 33px;
      border-radius: 5px;
      border: 1px solid @color_one;
      input {
        width: 550px;
        font-size: @font_size_18;
      }
      img {
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }
  }
  .result {
    margin-top: 56px;

    .result_title {
      height: 33px;
      line-height: 33px;
      text-align: center;
      font-size: @font_size_24;
    }
    .score_title {
      margin-top: 35px;
      line-height: 30px;
      padding-bottom: 17px;
      font-size: @font_size_22;
      border-bottom: 1px dashed #eee;
      .down {
        cursor: pointer;
        padding: 8px 17px;
        border-radius: 5px;

        text-align: center;
        background-color: @color_one;
        img {
          width: 14px;
          height: 14px;
        }
        span {
          color: #fff;
          margin-left: 5px;
          font-size: @font_size_16;
        }
      }
    }
    .score_info {
      width: 100%;
      height: 500px;
      margin-top: 23px;
      background-size: 100% 100%;
      background-image: url('~@/assets/image/img/24.png');
      .info_main {
        width: 609px;
        margin: 0 auto;
        text-align: center;
        .info_title {
          width: 100%;
          font-size: 30px;
          margin-top: 99px;
          color: #263035;
          line-height: 56px;
        }
        .time {
          height: 110px;
          font-size: 60px;
          margin-top: 20px;
          font-weight: bold;
          line-height: 110px;
          color: @color_one;
        }
        .rank {
          height: 42px;
          margin-top: 30px;
          color: #c59053;
          line-height: 42px;
          letter-spacing: 2px;
          font-size: 25px;
        }
      }
      .player {
        margin: 75px 0 18px 0;
        // margin: 113px 83px 18px 90px;
        .item {
          width: 25%;
          position: relative;
          justify-content: center;
          &::after {
            right: 0;
            width: 1px;
            display: block;
            content: '';
            height: 32px;
            position: absolute;
            background-color: #ccc;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }

          .item_box {
            text-align: center;
            font-size: @font_size_16;
            span {
              height: 30px;
              line-height: 30px;
              font-weight: bold;
            }
            p {
              color: #666666;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .cer {
      width: 100%;
      // height: 1106px;
      margin-top: 12px;
      img {
        width: 793px;
        // height: 100%;
      }
    }
  }
}
</style>
